@font-face {
  font-family: Dragon;
  src: url(./assets/fonts/Dragon-Serial-ExtraBold-Regular.ttf); }

@font-face {
  font-family: FreeSet;
  src: url(./assets/fonts/FreeSetW08DemiBold.ttf); }

html,
body {
  margin: 0;
  padding: 0;
  background-color: transparent !important; }

@media all {
  .page-break {
    display: none; } }

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact; } }

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto; } }

@page {
  size: A4 portrait;
  margin: 0mm; }

[style*="--aspect-ratio"] > :first-child {
  width: 100%; }

[style*="--aspect-ratio"] > img {
  height: auto; }

@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative; }
  [style*="--aspect-ratio"]::before {
    height: 0;
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio))); }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; }
  [style*="--scale-to"] {
    transform: scale(calc(var(--scale-to))/var(--scale-from)); } }

#test-env-badge {
  display: block;
  height: 24px;
  width: 96px;
  font-size: 10px;
  line-height: 24px;
  text-align: center;
  background-color: #d27f1f;
  color: #ffffff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 999;
  border-top-right-radius: 6px; }

#app-error {
  background-color: #965812;
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  #app-error * {
    color: #ffffff; }
  #app-error .inner {
    width: 320px; }
    #app-error .inner .inner-content {
      margin: 16px;
      padding: 16px;
      background-color: #d27f1f;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 16px; }

.ant-page-header .ant-page-header-heading .ant-page-header-heading-extra {
  top: 0px;
  right: 0px; }

.ant-avatar-image img {
  object-fit: cover; }

.ant-descriptions-item-label {
  font-weight: 600 !important; }

.ant-pagination {
  margin: 16px 16px !important; }
  .ant-pagination .ant-pagination-options > div {
    margin-top: 4px; }

.ant-select-selection {
  max-height: 240px;
  overflow: hidden;
  overflow-y: auto; }

.form-view-only,
.form-view-only * {
  border: none !important;
  background-color: transparent !important;
  cursor: default !important; }
  .form-view-only .ant-select-arrow,
  .form-view-only * .ant-select-arrow {
    opacity: 0 !important; }
  .form-view-only label,
  .form-view-only * label {
    font-weight: 600 !important; }
  .form-view-only .form-control,
  .form-view-only * .form-control {
    pointer-events: none !important; }

.custom-anchor {
  background-color: transparent !important; }
  .custom-anchor .ant-anchor {
    padding-left: 0px !important; }
    .custom-anchor .ant-anchor .ant-anchor-ink {
      display: none !important; }
    .custom-anchor .ant-anchor .ant-input-search {
      margin-bottom: 16px !important; }

.reg-button .ant-affix .ant-btn {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }

.ant-affix .custom-anchor .ant-input {
  border-radius: 0px !important;
  border-top: none !important; }

.ant-list-item-action li:last-child {
  padding-right: 0px; }

.ant-list.payments .ant-list-items .ant-list-item {
  cursor: pointer; }
  .ant-list.payments .ant-list-items .ant-list-item .anticon {
    color: #e1e1e1; }
  .ant-list.payments .ant-list-items .ant-list-item .ant-list-item-meta-title {
    font-size: 20px; }
  .ant-list.payments .ant-list-items .ant-list-item:hover .ant-list-item-meta-title,
  .ant-list.payments .ant-list-items .ant-list-item:hover .ant-list-item-meta-description,
  .ant-list.payments .ant-list-items .ant-list-item:hover .anticon {
    color: #007638; }

.__json-string__ {
  white-space: normal !important; }

#public {
  display: block;
  max-width: 1024px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #fafafa;
  transition: all 0.6s; }
  #public .ant-pagination {
    margin-right: 0px !important; }
  #public .logo {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 58px;
    width: 170px;
    z-index: 99;
    border-bottom: 1px solid #e8e8e8;
    background-color: #ffffff;
    line-height: 56px;
    text-align: right;
    padding: 4px 0px 4px 20px;
    transition: 0.6s all; }
    #public .logo img {
      width: 140px; }
  #public .ant-menu {
    text-align: right;
    margin-left: 170px; }
  #public.__public {
    max-width: 100vw;
    background-color: rgba(0, 0, 0, 0.5); }
    #public.__public .the-public-bg {
      background-color: #f0f0f0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: 0px;
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0px;
      left: 0px;
      z-index: -1; }
    #public.__public .logo {
      background-color: white;
      border-bottom-color: transparent; }
    #public.__public .ant-menu {
      background-color: white;
      border-bottom-color: transparent; }
      #public.__public .ant-menu .ant-menu-item,
      #public.__public .ant-menu .ant-menu-submenu {
        color: #14453d; }
        #public.__public .ant-menu .ant-menu-item.ant-menu-item-selected, #public.__public .ant-menu .ant-menu-item.ant-menu-submenu-selected, #public.__public .ant-menu .ant-menu-item.ant-menu-item-active, #public.__public .ant-menu .ant-menu-item.ant-menu-submenu-active,
        #public.__public .ant-menu .ant-menu-submenu.ant-menu-item-selected,
        #public.__public .ant-menu .ant-menu-submenu.ant-menu-submenu-selected,
        #public.__public .ant-menu .ant-menu-submenu.ant-menu-item-active,
        #public.__public .ant-menu .ant-menu-submenu.ant-menu-submenu-active {
          border-bottom-color: #14453d; }
          #public.__public .ant-menu .ant-menu-item.ant-menu-item-selected .ant-menu-submenu-title, #public.__public .ant-menu .ant-menu-item.ant-menu-submenu-selected .ant-menu-submenu-title, #public.__public .ant-menu .ant-menu-item.ant-menu-item-active .ant-menu-submenu-title, #public.__public .ant-menu .ant-menu-item.ant-menu-submenu-active .ant-menu-submenu-title,
          #public.__public .ant-menu .ant-menu-submenu.ant-menu-item-selected .ant-menu-submenu-title,
          #public.__public .ant-menu .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title,
          #public.__public .ant-menu .ant-menu-submenu.ant-menu-item-active .ant-menu-submenu-title,
          #public.__public .ant-menu .ant-menu-submenu.ant-menu-submenu-active .ant-menu-submenu-title {
            color: #14453d; }
    #public.__public .public-inner .home-page {
      display: flex;
      height: calc(100vh - 138px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0); }
      #public.__public .public-inner .home-page h1.ant-typography {
        text-align: center;
        color: #ffffff;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 47px;
        line-height: 100px; }
      #public.__public .public-inner .home-page .home-slides,
      #public.__public .public-inner .home-page .home-slides .home-slide,
      #public.__public .public-inner .home-page .home-slides .slick-slide,
      #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide {
        max-width: 100vw;
        width: 100vw;
        height: calc(100vh - 58px);
        background-color: transparent !important;
        overflow: hidden;
        position: relative; }
        #public.__public .public-inner .home-page .home-slides .home-slide-img,
        #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-img,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-img,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-img {
          width: 110vw;
          height: calc(110vh - 58px);
          margin-left: -5vw;
          margin-top: -5vh;
          object-fit: cover;
          object-position: center; }
        #public.__public .public-inner .home-page .home-slides .home-slide-center,
        #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-center,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-center,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-center {
          display: block;
          height: 200px;
          width: 200px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          border-radius: 100px;
          background-color: rgba(255, 255, 255, 0.75); }
          #public.__public .public-inner .home-page .home-slides .home-slide-center span,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-center span,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-center span,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-center span {
            display: block;
            height: 180px;
            width: 180px;
            background-color: #ffffff;
            margin: 10px;
            border-radius: 90px;
            text-align: center;
            font-weight: bold;
            font-size: 24px;
            line-height: 26px;
            padding-top: 64px; }
          #public.__public .public-inner .home-page .home-slides .home-slide-center.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-center.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-center.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-center.home-slide-mobile {
            height: 100px;
            width: 100px;
            border-radius: 50px; }
            #public.__public .public-inner .home-page .home-slides .home-slide-center.home-slide-mobile span,
            #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-center.home-slide-mobile span,
            #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-center.home-slide-mobile span,
            #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-center.home-slide-mobile span {
              height: 90px;
              width: 90px;
              margin: 5px;
              border-radius: 45px;
              font-size: 16px;
              line-height: 26px;
              padding-top: 24px; }
        #public.__public .public-inner .home-page .home-slides .home-slide-left,
        #public.__public .public-inner .home-page .home-slides .home-slide-right,
        #public.__public .public-inner .home-page .home-slides .home-slide-bottom,
        #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-left,
        #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-right,
        #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-bottom,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-left,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-right,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-bottom,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-left,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-right,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-bottom {
          display: block;
          position: absolute;
          height: 140px;
          width: 250px; }
          #public.__public .public-inner .home-page .home-slides .home-slide-left.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .home-slide-right.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .home-slide-bottom.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-left.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-right.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-bottom.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-left.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-right.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-bottom.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-left.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-right.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-bottom.home-slide-mobile {
            height: 70px;
            width: 125px; }
          #public.__public .public-inner .home-page .home-slides .home-slide-left .home-slide-link:first-child,
          #public.__public .public-inner .home-page .home-slides .home-slide-right .home-slide-link:first-child,
          #public.__public .public-inner .home-page .home-slides .home-slide-bottom .home-slide-link:first-child,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-left .home-slide-link:first-child,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-right .home-slide-link:first-child,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-bottom .home-slide-link:first-child,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-left .home-slide-link:first-child,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-right .home-slide-link:first-child,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-bottom .home-slide-link:first-child,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-left .home-slide-link:first-child,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-right .home-slide-link:first-child,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-bottom .home-slide-link:first-child {
            margin-bottom: 40px; }
        #public.__public .public-inner .home-page .home-slides .home-slide-left,
        #public.__public .public-inner .home-page .home-slides .home-slide-right,
        #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-left,
        #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-right,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-left,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-right,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-left,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-right {
          top: calc(((100vh - 58px) / 2) - 70px); }
        #public.__public .public-inner .home-page .home-slides .home-slide-left,
        #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-left,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-left,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-left {
          left: calc(((100vw - 250px) / 2) - 240px); }
          #public.__public .public-inner .home-page .home-slides .home-slide-left.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-left.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-left.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-left.home-slide-mobile {
            left: calc(((100vw - 125px) / 2) - 120px); }
        #public.__public .public-inner .home-page .home-slides .home-slide-right,
        #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-right,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-right,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-right {
          right: calc(((100vw - 250px) / 2) - 240px); }
          #public.__public .public-inner .home-page .home-slides .home-slide-right.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-right.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-right.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-right.home-slide-mobile {
            right: calc(((100vw - 125px) / 2) - 120px); }
        #public.__public .public-inner .home-page .home-slides .home-slide-bottom,
        #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-bottom,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-bottom,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-bottom {
          left: calc((100vw - 250px) / 2);
          top: calc(((100vh - 58px) / 2) - (80px - 220px)); }
          #public.__public .public-inner .home-page .home-slides .home-slide-bottom.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-bottom.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-bottom.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-bottom.home-slide-mobile {
            left: calc((100vw - 125px) / 2);
            top: calc(((100vh - 58px) / 2) - (80px - 160px)); }
        #public.__public .public-inner .home-page .home-slides .home-slide-link,
        #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-link,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-link,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-link {
          display: flex;
          height: 50px;
          overflow: hidden;
          flex-direction: row;
          align-items: center;
          cursor: pointer; }
          #public.__public .public-inner .home-page .home-slides .home-slide-link:hover span,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-link:hover span,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-link:hover span,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-link:hover span {
            opacity: 0.75; }
          #public.__public .public-inner .home-page .home-slides .home-slide-link img,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-link img,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-link img,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-link img {
            display: flex;
            height: 50px;
            width: 50px;
            border-radius: 25px;
            object-fit: cover;
            object-position: center;
            overflow: hidden; }
          #public.__public .public-inner .home-page .home-slides .home-slide-link span,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-link span,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-link span,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-link span {
            display: flex;
            flex: 1;
            padding: 12px 0px;
            background: #ffffff;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 20%, white 100%);
            font-size: 16px;
            border-radius: 0px 25px 25px 0px;
            color: #0d1467;
            justify-content: center;
            transition: opacity 0.3s; }
          #public.__public .public-inner .home-page .home-slides .home-slide-link.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-link.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-link.home-slide-mobile,
          #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-link.home-slide-mobile {
            height: 25px; }
            #public.__public .public-inner .home-page .home-slides .home-slide-link.home-slide-mobile img,
            #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-link.home-slide-mobile img,
            #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-link.home-slide-mobile img,
            #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-link.home-slide-mobile img {
              height: 25px;
              width: 25px;
              border-radius: 13px; }
            #public.__public .public-inner .home-page .home-slides .home-slide-link.home-slide-mobile span,
            #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-link.home-slide-mobile span,
            #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-link.home-slide-mobile span,
            #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-link.home-slide-mobile span {
              font-size: 8px; }
        #public.__public .public-inner .home-page .home-slides .home-slide-left .home-slide-link span,
        #public.__public .public-inner .home-page .home-slides .home-slide .home-slide-left .home-slide-link span,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide-left .home-slide-link span,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide .home-slide-left .home-slide-link span {
          border-radius: 25px 0px 0px 25px;
          background: linear-gradient(90deg, white 0%, white 80%, rgba(255, 255, 255, 0) 100%); }
        #public.__public .public-inner .home-page .home-slides.home-slide1 .home-slide-center span,
        #public.__public .public-inner .home-page .home-slides .home-slide.home-slide1 .home-slide-center span,
        #public.__public .public-inner .home-page .home-slides .slick-slide.home-slide1 .home-slide-center span,
        #public.__public .public-inner .home-page .home-slides .slick-slide .home-slide.home-slide1 .home-slide-center span {
          color: #007638; }
    #public.__public:not(.public__) .logo {
      background-color: #f0f0f0; }
    #public.__public:not(.public__) .ant-menu {
      background-color: #f0f0f0; }
    #public.__public:not(.public__) .public-inner {
      background-color: #fafafa; }
  #public .public-inner {
    padding: 40px 20px;
    transition: background-color 0.6s;
    min-height: calc(100vh - 58px); }
    #public .public-inner .public-form-container {
      max-width: 420px;
      min-height: 360px;
      margin-left: auto;
      margin-right: auto;
      margin-top: calc((100vh - (360px + 56px + 80px)) / 2); }
  #public .event-list {
    margin-top: 40px; }
    #public .event-list .ant-col {
      margin-bottom: 40px;
      height: calc(100vh / 3); }
      #public .event-list .ant-col .event-title .ant-btn {
        right: 16px; }

.badge-design-wrapper .ant-tabs-nav-scroll {
  text-align: center; }

.badge-design-wrapper .form-wrapper {
  margin: 0px 24px; }
  .badge-design-wrapper .form-wrapper .ant-row:not(:last-child) {
    margin-bottom: 1rem; }
  .badge-design-wrapper .form-wrapper .ant-row .ant-input-number,
  .badge-design-wrapper .form-wrapper .ant-row .ant-select {
    width: 100% !important; }

.badge-design-wrapper .ant-divider {
  margin-top: 24px !important; }

.badge-design-wrapper .badge-design {
  margin: 24px;
  position: relative; }
  .badge-design-wrapper .badge-design .save-btn,
  .badge-design-wrapper .badge-design .refresh-btn {
    position: absolute;
    top: 0px;
    right: 0px; }
  .badge-design-wrapper .badge-design .refresh-btn {
    right: 64px; }

.badge-canvas {
  height: 148.55mm;
  width: 105mm;
  background-color: #f0f0f0;
  position: relative; }
  .badge-canvas .bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1; }
  .badge-canvas .elements {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2; }
    .badge-canvas .elements img,
    .badge-canvas .elements h1,
    .badge-canvas .elements h3,
    .badge-canvas .elements div {
      display: block;
      position: absolute;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      object-fit: cover;
      object-position: center; }
      .badge-canvas .elements img span,
      .badge-canvas .elements h1 span,
      .badge-canvas .elements h3 span,
      .badge-canvas .elements div span {
        white-space: nowrap; }
  .badge-canvas.design {
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto; }
    .badge-canvas.design .elements img,
    .badge-canvas.design .elements h1,
    .badge-canvas.design .elements h3,
    .badge-canvas.design .elements div {
      border: dashed 1px #aaa; }

.preview-canvas {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 210mm; }

.home-page .ant-carousel .slick-slide {
  text-align: center;
  background: #d27f1f;
  overflow: hidden; }
  .home-page .ant-carousel .slick-slide img {
    width: 100%; }

.event-title {
  background-color: #666666;
  border: solid 1px #333333;
  border-radius: 4px;
  position: relative;
  margin-bottom: 0.5rem;
  overflow: hidden; }
  .event-title .ant-btn {
    position: absolute;
    top: 1rem; }
  .event-title .img {
    border-radius: 4px;
    overflow: hidden; }
  .event-title .ant-typography {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin-bottom: 0px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e6f7ff+0,e6f7ff+100&0+0,1+100 */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */ }
    .event-title .ant-typography a {
      color: #ffffff;
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      padding: 1rem;
      transition: opacity 0.3s; }
      .event-title .ant-typography a:hover, .event-title .ant-typography a:active {
        color: #ffffff;
        opacity: 0.65; }
  .event-title.single {
    margin: -40px -20px 20px -20px; }
    .event-title.single .stats {
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 20px;
      right: 20px; }
      .event-title.single .stats .ant-tag:last-child {
        margin-right: 0px; }
    .event-title.single,
    .event-title.single .img {
      border-radius: 0px; }
    .event-title.single .ant-typography a {
      padding: 20px; }

#private .ant-transfer-list {
  position: relative;
  display: inline-block;
  width: 300px;
  height: 830px;
  padding-top: 40px;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  border-radius: 4px; }

#private .logo {
  width: 100%;
  height: 64px;
  background-color: #0d2d28;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

#private .slct {
  appearance: none;
  border: none;
  font-weight: bold;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  color: #8b4141;
  background-color: #89977b; }

#private .category {
  font-weight: bold;
  box-sizing: border-box; }

#private .top-nav,
#private .top-nav .ant-menu {
  background-color: #ffffff; }

#private .top-nav .ant-input-search {
  border-bottom: 1px solid #e8e8e8; }

#private .left-nav,
#private .left-nav .ant-menu {
  background-color: #14453d; }
  #private .left-nav .ant-menu-item-selected,
  #private .left-nav .ant-menu-item-active,
  #private .left-nav .ant-menu .ant-menu-item-selected,
  #private .left-nav .ant-menu .ant-menu-item-active {
    background-color: #0f352f; }

#private .left-nav .ant-layout-sider-trigger {
  background-color: #123d36; }

#private .ant-card-head {
  background-color: #b0b198; }
  #private .ant-card-head .ant-card-extra a,
  #private .ant-card-head .ant-card-extra button {
    color: #ffffff;
    line-height: normal; }
    #private .ant-card-head .ant-card-extra a.ant-btn,
    #private .ant-card-head .ant-card-extra button.ant-btn {
      background-color: transparent;
      border: none;
      padding: 0px; }
    #private .ant-card-head .ant-card-extra a:hover,
    #private .ant-card-head .ant-card-extra button:hover {
      color: #e6e6e6; }

#private .ant-table-thead,
#private .ant-table-thead tr,
#private .ant-table-thead th {
  background-color: #bdbea9; }
  #private .ant-table-thead .ant-table-filter-icon.ant-dropdown-trigger:not(.ant-table-column-sorter),
  #private .ant-table-thead .anticon-filter.ant-dropdown-trigger:not(.ant-table-column-sorter),
  #private .ant-table-thead .ant-table-column-sorter:not(.ant-table-column-sorter),
  #private .ant-table-thead tr .ant-table-filter-icon.ant-dropdown-trigger:not(.ant-table-column-sorter),
  #private .ant-table-thead tr .anticon-filter.ant-dropdown-trigger:not(.ant-table-column-sorter),
  #private .ant-table-thead tr .ant-table-column-sorter:not(.ant-table-column-sorter),
  #private .ant-table-thead th .ant-table-filter-icon.ant-dropdown-trigger:not(.ant-table-column-sorter),
  #private .ant-table-thead th .anticon-filter.ant-dropdown-trigger:not(.ant-table-column-sorter),
  #private .ant-table-thead th .ant-table-column-sorter:not(.ant-table-column-sorter) {
    background-color: #b4b59d; }
  #private .ant-table-thead .ant-table-filter-icon.ant-dropdown-trigger,
  #private .ant-table-thead .ant-table-filter-icon.ant-dropdown-trigger .ant-table-column-sorter-up.off,
  #private .ant-table-thead .ant-table-filter-icon.ant-dropdown-trigger .ant-table-column-sorter-down.off,
  #private .ant-table-thead .anticon-filter.ant-dropdown-trigger,
  #private .ant-table-thead .anticon-filter.ant-dropdown-trigger .ant-table-column-sorter-up.off,
  #private .ant-table-thead .anticon-filter.ant-dropdown-trigger .ant-table-column-sorter-down.off,
  #private .ant-table-thead .ant-table-column-sorter,
  #private .ant-table-thead .ant-table-column-sorter .ant-table-column-sorter-up.off,
  #private .ant-table-thead .ant-table-column-sorter .ant-table-column-sorter-down.off,
  #private .ant-table-thead tr .ant-table-filter-icon.ant-dropdown-trigger,
  #private .ant-table-thead tr .ant-table-filter-icon.ant-dropdown-trigger .ant-table-column-sorter-up.off,
  #private .ant-table-thead tr .ant-table-filter-icon.ant-dropdown-trigger .ant-table-column-sorter-down.off,
  #private .ant-table-thead tr .anticon-filter.ant-dropdown-trigger,
  #private .ant-table-thead tr .anticon-filter.ant-dropdown-trigger .ant-table-column-sorter-up.off,
  #private .ant-table-thead tr .anticon-filter.ant-dropdown-trigger .ant-table-column-sorter-down.off,
  #private .ant-table-thead tr .ant-table-column-sorter,
  #private .ant-table-thead tr .ant-table-column-sorter .ant-table-column-sorter-up.off,
  #private .ant-table-thead tr .ant-table-column-sorter .ant-table-column-sorter-down.off,
  #private .ant-table-thead th .ant-table-filter-icon.ant-dropdown-trigger,
  #private .ant-table-thead th .ant-table-filter-icon.ant-dropdown-trigger .ant-table-column-sorter-up.off,
  #private .ant-table-thead th .ant-table-filter-icon.ant-dropdown-trigger .ant-table-column-sorter-down.off,
  #private .ant-table-thead th .anticon-filter.ant-dropdown-trigger,
  #private .ant-table-thead th .anticon-filter.ant-dropdown-trigger .ant-table-column-sorter-up.off,
  #private .ant-table-thead th .anticon-filter.ant-dropdown-trigger .ant-table-column-sorter-down.off,
  #private .ant-table-thead th .ant-table-column-sorter,
  #private .ant-table-thead th .ant-table-column-sorter .ant-table-column-sorter-up.off,
  #private .ant-table-thead th .ant-table-column-sorter .ant-table-column-sorter-down.off {
    color: #000000; }
  #private .ant-table-thead .ant-table-filter-icon.ant-dropdown-trigger:not(.ant-table-column-sorter):hover,
  #private .ant-table-thead .anticon-filter.ant-dropdown-trigger:not(.ant-table-column-sorter):hover,
  #private .ant-table-thead .ant-table-column-sorter:not(.ant-table-column-sorter):hover,
  #private .ant-table-thead tr .ant-table-filter-icon.ant-dropdown-trigger:not(.ant-table-column-sorter):hover,
  #private .ant-table-thead tr .anticon-filter.ant-dropdown-trigger:not(.ant-table-column-sorter):hover,
  #private .ant-table-thead tr .ant-table-column-sorter:not(.ant-table-column-sorter):hover,
  #private .ant-table-thead th .ant-table-filter-icon.ant-dropdown-trigger:not(.ant-table-column-sorter):hover,
  #private .ant-table-thead th .anticon-filter.ant-dropdown-trigger:not(.ant-table-column-sorter):hover,
  #private .ant-table-thead th .ant-table-column-sorter:not(.ant-table-column-sorter):hover {
    background-color: #c6c7b5; }
  #private .ant-table-thead .ant-table-filter-icon.ant-dropdown-trigger.ant-table-filter-selected,
  #private .ant-table-thead .ant-table-filter-icon.ant-dropdown-trigger .ant-table-column-sorter-up.on,
  #private .ant-table-thead .ant-table-filter-icon.ant-dropdown-trigger .ant-table-column-sorter-down.on,
  #private .ant-table-thead .anticon-filter.ant-dropdown-trigger.ant-table-filter-selected,
  #private .ant-table-thead .anticon-filter.ant-dropdown-trigger .ant-table-column-sorter-up.on,
  #private .ant-table-thead .anticon-filter.ant-dropdown-trigger .ant-table-column-sorter-down.on,
  #private .ant-table-thead .ant-table-column-sorter.ant-table-filter-selected,
  #private .ant-table-thead .ant-table-column-sorter .ant-table-column-sorter-up.on,
  #private .ant-table-thead .ant-table-column-sorter .ant-table-column-sorter-down.on,
  #private .ant-table-thead tr .ant-table-filter-icon.ant-dropdown-trigger.ant-table-filter-selected,
  #private .ant-table-thead tr .ant-table-filter-icon.ant-dropdown-trigger .ant-table-column-sorter-up.on,
  #private .ant-table-thead tr .ant-table-filter-icon.ant-dropdown-trigger .ant-table-column-sorter-down.on,
  #private .ant-table-thead tr .anticon-filter.ant-dropdown-trigger.ant-table-filter-selected,
  #private .ant-table-thead tr .anticon-filter.ant-dropdown-trigger .ant-table-column-sorter-up.on,
  #private .ant-table-thead tr .anticon-filter.ant-dropdown-trigger .ant-table-column-sorter-down.on,
  #private .ant-table-thead tr .ant-table-column-sorter.ant-table-filter-selected,
  #private .ant-table-thead tr .ant-table-column-sorter .ant-table-column-sorter-up.on,
  #private .ant-table-thead tr .ant-table-column-sorter .ant-table-column-sorter-down.on,
  #private .ant-table-thead th .ant-table-filter-icon.ant-dropdown-trigger.ant-table-filter-selected,
  #private .ant-table-thead th .ant-table-filter-icon.ant-dropdown-trigger .ant-table-column-sorter-up.on,
  #private .ant-table-thead th .ant-table-filter-icon.ant-dropdown-trigger .ant-table-column-sorter-down.on,
  #private .ant-table-thead th .anticon-filter.ant-dropdown-trigger.ant-table-filter-selected,
  #private .ant-table-thead th .anticon-filter.ant-dropdown-trigger .ant-table-column-sorter-up.on,
  #private .ant-table-thead th .anticon-filter.ant-dropdown-trigger .ant-table-column-sorter-down.on,
  #private .ant-table-thead th .ant-table-column-sorter.ant-table-filter-selected,
  #private .ant-table-thead th .ant-table-column-sorter .ant-table-column-sorter-up.on,
  #private .ant-table-thead th .ant-table-column-sorter .ant-table-column-sorter-down.on {
    color: #ffffff !important; }

#private .ant-card .ant-table table,
#private .ant-card .ant-table table th,
#private .ant-card .ant-table table td,
#private .ant-card .ant-table table thead,
#private .ant-card .ant-table table thead th,
#private .ant-card .ant-table table thead td,
#private .ant-card .ant-table table tbody,
#private .ant-card .ant-table table tbody th,
#private .ant-card .ant-table table tbody td,
#private .ant-card .ant-table .ant-table-placeholder,
#private .ant-card .ant-table .ant-table-placeholder th,
#private .ant-card .ant-table .ant-table-placeholder td,
#private .ant-card .ant-table .ant-table-placeholder thead,
#private .ant-card .ant-table .ant-table-placeholder thead th,
#private .ant-card .ant-table .ant-table-placeholder thead td,
#private .ant-card .ant-table .ant-table-placeholder tbody,
#private .ant-card .ant-table .ant-table-placeholder tbody th,
#private .ant-card .ant-table .ant-table-placeholder tbody td {
  border-radius: 0px 0px 0px 0px !important; }

#private .event-title.single {
  margin: 0px -16px 20px -16px; }

.csv-reader-input {
  padding: 16px;
  display: block;
  margin: 16px auto;
  border: 1px solid #ccc;
  border-radius: 4px; }

.reg-form .ant-row.ant-form-item {
  margin-bottom: 0px; }

.attend-events {
  margin-bottom: 24px; }
  @media screen and (min-width: 900px) {
    .attend-events {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 24px;
      row-gap: 24px; } }
  .attend-events .events-search .ant-card {
    width: 100%; }
  .attend-events.events-list .event-item .event-item-card {
    width: 100%; }
    .attend-events.events-list .event-item .event-item-card .event-item-cover,
    .attend-events.events-list .event-item .event-item-card .event-item-cover img {
      --aspect-ratio: (1200/400);
      width: 100%;
      max-width: 100%; }
      .attend-events.events-list .event-item .event-item-card .event-item-cover:not(.event-item-cover),
      .attend-events.events-list .event-item .event-item-card .event-item-cover img:not(.event-item-cover) {
        object-fit: cover;
        object-position: center; }
    .attend-events.events-list .event-item .event-item-card .event-item-start-date {
      color: #965812; }
    .attend-events.events-list .event-item .event-item-card .event-item-start-date,
    .attend-events.events-list .event-item .event-item-card .event-item-title,
    .attend-events.events-list .event-item .event-item-card .event-item-title {
      margin-top: 0px;
      margin-bottom: 0px; }

.padded {
  padding: 20px; }

.single-event {
  position: relative; }
  .single-event .single-event-background {
    position: absolute;
    height: auto;
    width: 100vw;
    height: 50vh;
    top: -40px;
    left: -20px;
    z-index: 0;
    overflow: hidden; }
    .single-event .single-event-background img {
      width: 120vw;
      height: 70vh;
      margin-left: -10vw;
      margin-top: -10vh;
      object-fit: cover;
      object-position: center;
      pointer-events: none;
      filter: blur(20px); }
  .single-event .event-content-card {
    z-index: 1; }
    .single-event .event-content-card .event-content-body {
      width: 100%;
      border: none;
      border-radius: 4px; }
      .single-event .event-content-card .event-content-body .ant-card-body {
        padding: 0px; }
        .single-event .event-content-card .event-content-body .ant-card-body .event-item-cover,
        .single-event .event-content-card .event-content-body .ant-card-body .event-item-cover img {
          width: 100%;
          max-width: 100%;
          border-top-left-radius: 4px; }
          .single-event .event-content-card .event-content-body .ant-card-body .event-item-cover:not(.event-item-cover),
          .single-event .event-content-card .event-content-body .ant-card-body .event-item-cover img:not(.event-item-cover) {
            object-fit: cover;
            object-position: center; }
        .single-event .event-content-card .event-content-body .ant-card-body .event-item-title h2 {
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 28px;
          line-height: 28px;
          overflow-wrap: break-word; }
        .single-event .event-content-card .event-content-body .ant-card-body .event-item-title h2,
        .single-event .event-content-card .event-content-body .ant-card-body .event-item-title span {
          padding-left: 20px;
          padding-right: 20px; }
        .single-event .event-content-card .event-content-body .ant-card-body .event-reg-area {
          background-color: #efefef; }
          .single-event .event-content-card .event-content-body .ant-card-body .event-reg-area .padded {
            padding-top: 10px;
            padding-bottom: 10px; }
        .single-event .event-content-card .event-content-body .ant-card-body .stats .ant-descriptions {
          margin-top: 40px; }
          .single-event .event-content-card .event-content-body .ant-card-body .stats .ant-descriptions .ant-descriptions-title {
            font-size: 24px;
            font-weight: normal; }
        .single-event .event-content-card .event-content-body .ant-card-body .maps {
          width: 100%;
          max-width: 100%;
          background-color: #cccccc;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px; }
          .single-event .event-content-card .event-content-body .ant-card-body .maps .maps-iframe {
            width: 100%;
            max-width: 100%;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px; }

#CustomForm_dial_code .ant-select-selection-selected-value,
#CustomForm_dial_code .ant-select-arrow-icon {
  margin-top: 4px !important; }

.sms-iframe {
  width: calc(100vw - 232px);
  height: calc(100vh - 96px);
  border: none; }

.public-form-container.survey-form {
  max-width: 800px !important; }
  .public-form-container.survey-form .form-group > label {
    padding-top: 20px; }

.optional-payment-phone {
  margin-bottom: 0px !important; }
  .optional-payment-phone,
  .optional-payment-phone .ant-form-item-control {
    line-height: normal !important; }
