$brand-yellow: #d27f1f;
$brand-yellow-dark: #965812;
$brand-green: #007638;
$brand-grey: #f0f0f0;
$brand-light: #ffffff;
$brand-dark: #000000;
$brand-beige: #bdbea9;
$brand-dark-green: #14453d;
$brand-dark-blue: #0d1467;

@font-face {
  font-family: Dragon;
  src: url(./assets/fonts/Dragon-Serial-ExtraBold-Regular.ttf);
}
@font-face {
  font-family: FreeSet;
  src: url(./assets/fonts/FreeSetW08DemiBold.ttf);
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: transparent !important;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: A4 portrait;
  margin: 0mm;
}

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}
@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    height: 0;
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  [style*="--scale-to"] {
    transform: scale(calc(var(--scale-to)) / (var(--scale-from)));
  }
}

#test-env-badge {
  display: block;
  height: 24px;
  width: 96px;
  font-size: 10px;
  line-height: 24px;
  text-align: center;
  background-color: $brand-yellow;
  color: $brand-light;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 999;
  border-top-right-radius: 6px;
}

#app-error {
  background-color: $brand-yellow-dark;
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  * {
    color: $brand-light;
  }
  .inner {
    width: 320px;
    .inner-content {
      margin: 16px;
      padding: 16px;
      background-color: $brand-yellow;
      border-radius: 4px;
      box-shadow: rgba($brand-dark, 0.3) 0px 0px 16px;
    }
  }
}

.ant-page-header {
  .ant-page-header-heading {
    .ant-page-header-heading-extra {
      $heading-extra: &;
      top: 0px;
      right: 0px;
    }
  }
}

.ant-avatar-image {
  img {
    object-fit: cover;
  }
}

.ant-descriptions-item-label {
  font-weight: 600 !important;
}

.ant-pagination {
  margin: 16px 16px !important;
  .ant-pagination-options {
    & > div {
      margin-top: 4px;
    }
  }
}

.ant-select-selection {
  max-height: 240px;
  overflow: hidden;
  overflow-y: auto;
}

.form-view-only {
  &,
  * {
    border: none !important;
    background-color: transparent !important;
    cursor: default !important;
    .ant-select-arrow {
      opacity: 0 !important;
    }
    label {
      font-weight: 600 !important;
    }
    .form-control {
      pointer-events: none !important;
    }
  }
}

.custom-anchor {
  background-color: transparent !important;
  .ant-anchor {
    padding-left: 0px !important;
    .ant-anchor-ink {
      display: none !important;
    }
    .ant-input-search {
      margin-bottom: 16px !important;
    }
  }
}

.reg-button {
  .ant-affix {
    .ant-btn {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

.ant-affix {
  .custom-anchor {
    .ant-input {
      border-radius: 0px !important;
      border-top: none !important;
    }
  }
}

.ant-list-item-action {
  li:last-child {
    padding-right: 0px;
  }
}

.ant-list {
  &.payments {
    .ant-list-items {
      .ant-list-item {
        cursor: pointer;
        .anticon {
          color: darken($brand-grey, 6%);
        }
        .ant-list-item-meta-title {
          font-size: 20px;
        }
        &:hover {
          .ant-list-item-meta-title,
          .ant-list-item-meta-description,
          .anticon {
            color: $brand-green;
          }
        }
      }
    }
  }
}

.__json-string__ {
  white-space: normal !important;
}

#public {
  display: block;
  max-width: 1024px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: lighten($brand-grey, 4%);
  transition: all 0.6s;

  // * {
  //   max-width: 100vw;
  // }

  .ant-pagination {
    margin-right: 0px !important;
  }
  .logo {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 58px;
    width: 170px;
    z-index: 99;
    border-bottom: 1px solid #e8e8e8;
    background-color: #ffffff;
    line-height: 56px;
    text-align: right;
    padding: 4px 0px 4px 20px;
    transition: 0.6s all;
    img {
      width: 140px;
    }
  }
  .ant-menu {
    text-align: right;
    margin-left: 170px;
  }
  &.__public {
    max-width: 100vw;
    background-color: rgba($brand-dark, 0.5);
    .the-public-bg {
      background-color: $brand-grey;
      // background-image: url(http://197.243.22.32/api/handler/photo/app/home_bg/16/2000);
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: 0px;
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0px;
      left: 0px;
      z-index: -1;
    }
    .logo {
      background-color: rgba($brand-light, 1);
      border-bottom-color: transparent;
    }
    .ant-menu {
      background-color: rgba($brand-light, 1);
      border-bottom-color: transparent;
      .ant-menu-item,
      .ant-menu-submenu {
        color: $brand-dark-green;
        &.ant-menu-item-selected,
        &.ant-menu-submenu-selected,
        &.ant-menu-item-active,
        &.ant-menu-submenu-active {
          .ant-menu-submenu-title {
            color: $brand-dark-green;
          }
          border-bottom-color: $brand-dark-green;
        }
      }
    }
    .public-inner {
      .home-page {
        display: flex;
        height: calc(100vh - 138px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba($brand-dark, 0);
        h1.ant-typography {
          text-align: center;
          color: $brand-light;
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 47px;
          line-height: 100px;
        }
        .home-slides {
          &,
          .slick-slide {
            &,
            .home-slide {
              max-width: 100vw;
              width: 100vw;
              height: calc(100vh - 58px);
              background-color: transparent !important;
              overflow: hidden;
              position: relative;
              .home-slide-img {
                width: 110vw;
                height: calc(110vh - 58px);
                margin-left: -5vw;
                margin-top: -5vh;
                object-fit: cover;
                object-position: center;
              }
              .home-slide-center {
                display: block;
                height: 200px;
                width: 200px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                border-radius: 100px;
                background-color: rgba($brand-light, 0.75);
                span {
                  display: block;
                  height: 180px;
                  width: 180px;
                  background-color: $brand-light;
                  margin: 10px;
                  border-radius: 90px;
                  text-align: center;
                  font-weight: bold;
                  font-size: 24px;
                  line-height: 26px;
                  padding-top: 64px;
                }
                &.home-slide-mobile {
                  height: 100px;
                  width: 100px;
                  border-radius: 50px;
                  span {
                    height: 90px;
                    width: 90px;
                    margin: 5px;
                    border-radius: 45px;
                    font-size: 16px;
                    line-height: 26px;
                    padding-top: 24px;
                  }
                }
              }
              .home-slide-left,
              .home-slide-right,
              .home-slide-bottom {
                display: block;
                position: absolute;
                height: 140px;
                width: 250px;
                &.home-slide-mobile {
                  height: 70px;
                  width: 125px;
                }
                .home-slide-link:first-child {
                  margin-bottom: 40px;
                }
              }
              .home-slide-left,
              .home-slide-right {
                top: calc(((100vh - 58px) / 2) - 70px);
              }
              .home-slide-left {
                left: calc(((100vw - 250px) / 2) - 240px);
                &.home-slide-mobile {
                  left: calc(((100vw - 125px) / 2) - 120px);
                }
              }
              .home-slide-right {
                right: calc(((100vw - 250px) / 2) - 240px);
                &.home-slide-mobile {
                  right: calc(((100vw - 125px) / 2) - 120px);
                }
              }
              .home-slide-bottom {
                left: calc((100vw - 250px) / 2);
                top: calc(((100vh - 58px) / 2) - (80px - 220px));
                &.home-slide-mobile {
                  left: calc((100vw - 125px) / 2);
                  top: calc(((100vh - 58px) / 2) - (80px - 160px));
                }
              }
              .home-slide-link {
                display: flex;
                height: 50px;
                overflow: hidden;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                &:hover {
                  span {
                    opacity: 0.75;
                  }
                }
                img {
                  display: flex;
                  height: 50px;
                  width: 50px;
                  border-radius: 25px;
                  object-fit: cover;
                  object-position: center;
                  overflow: hidden;
                }
                span {
                  display: flex;
                  flex: 1;
                  padding: 12px 0px;
                  background: $brand-light;
                  background: linear-gradient(
                    90deg,
                    rgba($brand-light, 0) 0%,
                    rgba($brand-light, 1) 20%,
                    rgba($brand-light, 1) 100%
                  );
                  font-size: 16px;
                  border-radius: 0px 25px 25px 0px;
                  color: $brand-dark-blue;
                  justify-content: center;
                  transition: opacity 0.3s;
                }
                &.home-slide-mobile {
                  height: 25px;
                  img {
                    height: 25px;
                    width: 25px;
                    border-radius: 13px;
                  }
                  span {
                    font-size: 8px;
                  }
                }
              }
              .home-slide-left {
                .home-slide-link {
                  span {
                    border-radius: 25px 0px 0px 25px;
                    background: linear-gradient(
                      90deg,
                      rgba($brand-light, 1) 0%,
                      rgba($brand-light, 1) 80%,
                      rgba($brand-light, 0) 100%
                    );
                  }
                }
              }
              &.home-slide1 {
                .home-slide-center {
                  span {
                    color: $brand-green;
                  }
                }
              }
            }
          }
        }
      }
    }
    &:not(.public__) {
      .logo {
        background-color: rgba($brand-grey, 1);
      }
      .ant-menu {
        background-color: rgba($brand-grey, 1);
      }
      .public-inner {
        background-color: darken($brand-light, 2%);
      }
    }
  }
  .public-inner {
    padding: 40px 20px;
    transition: background-color 0.6s;
    min-height: calc(100vh - 58px);
    .public-form-container {
      max-width: 420px;
      min-height: 360px;
      margin-left: auto;
      margin-right: auto;
      margin-top: calc((100vh - (360px + 56px + 80px)) / 2);
    }
  }
  .event-list {
    margin-top: 40px;
    .ant-col {
      margin-bottom: 40px;
      height: calc(100vh / 3);
      .event-title {
        .ant-btn {
          right: 16px;
        }
      }
    }
  }
}

.badge-design-wrapper {
  .ant-tabs-nav-scroll {
    text-align: center;
  }
  .form-wrapper {
    margin: 0px 24px;
    .ant-row {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      .ant-input-number,
      .ant-select {
        width: 100% !important;
      }
    }
  }
  .ant-divider {
    margin-top: 24px !important;
  }
  .badge-design {
    margin: 24px;
    position: relative;
    .save-btn,
    .refresh-btn {
      position: absolute;
      top: 0px;
      right: 0px;
    }
    .refresh-btn {
      right: 64px;
    }
  }
}

.badge-canvas {
  height: 148.55mm;
  width: 105mm;
  background-color: #f0f0f0;
  position: relative;
  .bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }
  .elements {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    img,
    h1,
    h3,
    div {
      display: block;
      position: absolute;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      object-fit: cover;
      object-position: center;
      span {
        white-space: nowrap;
      }
    }
  }
  &.design {
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    .elements {
      img,
      h1,
      h3,
      div {
        border: dashed 1px #aaa;
      }
    }
  }
}

.preview-canvas {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 210mm;
}

.home-page {
  .ant-carousel {
    .slick-slide {
      text-align: center;
      background: $brand-yellow;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
}

.event-title {
  background-color: #666666;
  border: solid 1px #333333;
  border-radius: 4px;
  position: relative;
  margin-bottom: 0.5rem;
  overflow: hidden;
  .ant-btn {
    position: absolute;
    top: 1rem;
  }
  .img {
    border-radius: 4px;
    overflow: hidden;
  }
  .ant-typography {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin-bottom: 0px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e6f7ff+0,e6f7ff+100&0+0,1+100 */
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
    a {
      color: #ffffff;
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      padding: 1rem;
      transition: opacity 0.3s;
      &:hover,
      &:active {
        color: #ffffff;
        opacity: 0.65;
      }
    }
  }
  &.single {
    margin: -40px -20px 20px -20px;
    .stats {
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 20px;
      right: 20px;
      .ant-tag:last-child {
        margin-right: 0px;
      }
    }
    &,
    .img {
      border-radius: 0px;
    }
    .ant-typography {
      a {
        padding: 20px;
      }
    }
  }
}
// .event-tabs {
//   margin-top: -63px !important;
//   .ant-tabs-tab {
//     color: $brand-light;
//     &.ant-tabs-tab-active,
//     &:hover {
//       color: $brand-green;
//     }
//   }
// }

#private {
  .ant-transfer-list {
    position: relative;
    display: inline-block;
    width: 300px;
    height: 830px;
    padding-top: 40px;
    vertical-align: middle;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}
  .logo {
    width: 100%;
    height: 64px;
    background-color: darken($brand-dark-green, 6%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .slct {
    appearance: none;
    border: none;
    font-weight: bold ;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    color: rgb(139, 65, 65) ;
    background-color: rgb(137, 151, 123);
  }
  .category{
    font-weight: bold ;
    box-sizing: border-box;
  }

  .top-nav {
    &,   
   .ant-menu {
      background-color: $brand-light;
    }
    .ant-input-search {
      border-bottom: 1px solid #e8e8e8;
    }

    
  // .top-nav {
  //   &,
  //   .ant-menu {
  //     background-color: $brand-light;
  //     padding-left: 0px !important;
  //   }
  //         .ant-menu-ink {
  //       display: none !important;
  //     }

  //   .ant-input-search {
  //     border-bottom: 16px !important;
  //   }
  // }
  
  // .custom-anchor {
  //   background-color: transparent !important;
  //   .ant-anchor {
  //     padding-left: 0px !important;
  //     .ant-anchor-ink {
  //       display: none !important;
  //     }
  //     .ant-input-search {
  //       margin-bottom: 16px !important;
  //     }
  //   }
  // }







    
  }
  .left-nav {
    &,
    .ant-menu {
      background-color: $brand-dark-green;
      .ant-menu-item-selected,
      .ant-menu-item-active {
        background-color: darken($brand-dark-green, 4%);
      }
    }
    .ant-layout-sider-trigger {
      background-color: darken($brand-dark-green, 2%);
    }  
  }
  .ant-card-head {
    background-color: darken($brand-beige, 6%);
    .ant-card-extra {
      a,
      button {
        color: $brand-light;
        line-height: normal;
        &.ant-btn {
          background-color: transparent;
          border: none;
          padding: 0px;
        }
        &:hover {
          color: darken($brand-light, 10%);
        }
      }
    }
  }
  .ant-table-thead {
    &,
    tr,
    th {
      background-color: $brand-beige;
      .ant-table-filter-icon.ant-dropdown-trigger,
      .anticon-filter.ant-dropdown-trigger,
      .ant-table-column-sorter {
        &:not(.ant-table-column-sorter) {
          background-color: darken($brand-beige, 4%);
        }
        &,
        .ant-table-column-sorter-up.off,
        .ant-table-column-sorter-down.off {
          color: $brand-dark;
        }
        &:not(.ant-table-column-sorter):hover {
          background-color: lighten($brand-beige, 4%);
        }
        &.ant-table-filter-selected,
        .ant-table-column-sorter-up.on,
        .ant-table-column-sorter-down.on {
          color: $brand-light !important;
        }
      }
    }
  }
  .ant-card {
    .ant-table {
      table,
      .ant-table-placeholder {
        &,
        thead,
        tbody {
          &,
          th,
          td {
            border-radius: 0px 0px 0px 0px !important;
          }
        }
      }
    }
  }
  .event-title {
    &.single {
      margin: 0px -16px 20px -16px;
    }
  }
}

.csv-reader-input {
  padding: 16px;
  display: block;
  margin: 16px auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.reg-form {
  .ant-row.ant-form-item {
    margin-bottom: 0px;
  }
}

.attend-events {
  @media screen and (min-width: 900px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    row-gap: 24px;
  }
  margin-bottom: 24px;
  .events-search {
    .ant-card {
      width: 100%;
    }
  }
  &.events-list {
    .event-item {
      .event-item-card {
        width: 100%;
        .event-item-cover {
          &,
          img {
            --aspect-ratio: (1200/400);
            width: 100%;
            max-width: 100%;
            &:not(.event-item-cover) {
              object-fit: cover;
              object-position: center;
            }
          }
        }
        .event-item-start-date {
          color: $brand-yellow-dark;
        }
        .event-item-start-date,
        .event-item-title,
        .event-item-title {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
.padded {
  padding: 20px;
}

.single-event {
  position: relative;
  .single-event-background {
    position: absolute;
    height: auto;
    width: 100vw;
    height: 50vh;
    top: -40px;
    left: -20px;
    z-index: 0;
    overflow: hidden;
    img {
      width: 120vw;
      height: 70vh;
      margin-left: -10vw;
      margin-top: -10vh;
      object-fit: cover;
      object-position: center;
      pointer-events: none;
      filter: blur(20px);
    }
  }
  .event-content-card {
    z-index: 1;
    .event-content-body {
      width: 100%;
      border: none;
      border-radius: 4px;
      .ant-card-body {
        padding: 0px;
        .event-item-cover {
          &,
          img {
            width: 100%;
            max-width: 100%;
            border-top-left-radius: 4px;
            &:not(.event-item-cover) {
              object-fit: cover;
              object-position: center;
            }
          }
        }
        .event-item-title {
          h2 {
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 28px;
            line-height: 28px;
            overflow-wrap: break-word;
          }
          h2,
          span {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
        .event-reg-area {
          background-color: #efefef;
          .padded {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
        .stats {
          .ant-descriptions {
            margin-top: 40px;
            .ant-descriptions-title {
              font-size: 24px;
              font-weight: normal;
            }
          }
        }
        .maps {
          width: 100%;
          max-width: 100%;
          background-color: #cccccc;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          .maps-iframe {
            width: 100%;
            max-width: 100%;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }
}

#CustomForm_dial_code {
  .ant-select-selection-selected-value,
  .ant-select-arrow-icon {
    margin-top: 4px !important;
  }
}

.sms-iframe {
  width: calc(100vw - 232px);
  height: calc(100vh - 96px);
  border: none;
}

// .ant-table-scroll {
//   overflow-y: auto !important;
// }

.public-form-container.survey-form {
  max-width: 800px !important;
  .form-group {
    & > label {
      padding-top: 20px;
    }
  }
}

// .form-group {
//   .padding-left + label {
//     padding-top: 0px;
//     margin-left: 40px;
//   }
//   .padding-left {
//     margin-left: 40px;
//   }
// }

.optional-payment-phone {
  margin-bottom: 0px !important;
  &,
  .ant-form-item-control {
    line-height: normal !important;
  }
}
